import Grid from '@material-ui/core/Grid';
import React from 'react';
import {Progress, StyledLinearProgress} from './../styled/index';

const LoadingProgress: React.FC = () => {
    return (
        <Progress>
            <Grid container direction="column" justify="center" alignItems="center">
                <StyledLinearProgress />
            </Grid>
        </Progress>
    );
};

export default LoadingProgress;
