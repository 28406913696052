import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import russian from './russian';
import ukrainian from './ukrainian';

export function setLanguage(language) {
    const resources = {
        ru: {
            translation: russian
        },
        ua: {
            translation: ukrainian
        }
    };

    i18n.use(initReactI18next).init({
        resources,
        lng: language,
        fallbackLng: 'ua',

        interpolation: {
            escapeValue: false
        }
    });

    return i18n;
}
