export default {
    house_not_published: 'Дом не опубликован',
    user: 'Пользователь',
    date: 'Дата',
    name: 'Название',
    not_defined: 'Не определено',
    yes: 'Да',
    no: 'Нет',
    form_name: 'Имя',
    house_name: 'Название дома',
    visible_in_markup: 'Виден в разметке',
    flat_price_in_house: 'Стоимость квартир в доме',
    parking: 'Парковка',
    commercial_premises: 'Коммерческое помещение',
    exist: 'Есть',
    not_exist: 'Нет',
    no_data: 'Нет данных',
    form_reason: 'Причина обращения',
    form_send: 'Отправить',
    sales_department: 'Отдел продаж',
    reception: 'Приемная',
    floor_without_layouts: 'На этом этаже нет отмеченных планировок квартир',
    floor_without_plan: 'К этому этажу не привязана планировка',
    floor: 'Этаж',
    entrance: 'Подъезд',
    flat_number: 'Номер квартиры',
    price_m2: 'Цена м2',
    price_for_meter: 'Цена за метр',
    price_unavailable: 'Цена недоступна',
    full_price: 'Полная цена',
    price: 'Цена',
    levels_amount: 'Количество уровней',
    choose_flats: 'Выберите квартиру',
    rooms_amount: 'Количество комнат',
    one_room: 'Однокомнатные',
    two_rooms: 'Двухкомнатные',
    three_rooms: 'Трёхкомнатные',
    four_rooms: 'От четырёх комнат',
    rooms: 'Комнат',
    area: 'Площадь',
    status: 'Статус',
    chess_view: 'Отображение',
    list: 'Список',
    tile: 'Плитка',
    tile_plus: 'Плитка+',
    floor_plan: 'План этажа',
    other_premises: 'Другие помещения',
    view_mode: 'Режим просмотра',
    object: 'Объект',
    object_type: 'Тип объекта',
    object_name: 'Название объекта',
    city: 'Город',
    undergroundStation: 'Метро',
    district: 'Район',
    address: 'Строительный адрес',
    class: 'Класс',
    sections: 'Количество секций',
    section: 'Секция',
    levels: 'Этажность',
    beginDate: 'Начало строительства',
    endDate: 'Конец строительства',
    cancel: 'Отмена',
    info: 'Информация',
    print_info: 'Распечатать инофрмацию',
    download_pdf: 'Скачать PDF',
    continue: 'Далее',
    no_vr_photo: 'Нет VR фото',
    no_photo: 'Нет фото',
    found: 'Найдено',
    from_them_free: 'помещений из них свободно',
    send_order: 'Оставить заявку',
    enter_data_and_send_order: 'введите данные и оставьте заявку',
    error_text: 'Телефон технической поддержки: +380956979583',
    start_of_construction: 'Начало строительства',
    end_of_construction: 'Конец строительства',
    SOLD_OUT: 'Продано',
    FREE: 'Свободно',
    RESERVED: 'Резерв',
    BOOKED: 'Забронировано',
    UNAVAILABLE: 'Недоступно',
    DOCUMENTS_IN_PROGRESS: 'Оформление документов',
    houser_remake: {
        photo_is_not_exist: 'Фото пока отсутствуют.',
        check_project: 'А пока мы готовим для Вас фото, посмотрите другие дизайн-проекты на сайте партнеров:'
    }
};
