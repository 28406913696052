import React from 'react';
import ErrorPic from './error.svg';
import {ErrorText, StyleForm} from './Login.styles';

export default function ServerAuthError() {
    return (
        <StyleForm>
            <img src={ErrorPic} alt="ErrorPic" />
            <ErrorText>
                Ошибка работы сервера. Приносим извинения за временные неудобства.
                <br />
                Обратитесь в службу технической поддержки(круглосуточно): <br />
                +380-95-697-95-83
            </ErrorText>
        </StyleForm>
    );
}
