import styled from 'styled-components';

export const LoginForm = styled.div`
    width: 60%;
    margin: 300px auto;

    &.error {
        margin: 30px auto;
    }
`;

export const StyleForm = styled.div`
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const ErrorText = styled.div`
    text-align: center;
    margin-top: 30px;
    line-height: 1.5;
`;
