export default {
    house_not_published: 'Будинок не опублікований',
    user: 'Користувач',
    date: 'Дата',
    name: 'Назва',
    not_defined: 'Не визначене',
    yes: 'Так',
    no: 'Нi',
    form_name: "Iм'я",
    house_name: 'Назва дому',
    visible_in_markup: 'Видно в розмітці',
    flat_price_in_house: 'Вартість квартир у будинку',
    parking: 'Парковка',
    commercial_premises: 'Комерційне приміщення',
    exist: 'Є',
    not_exist: 'Немає',
    no_data: 'Немає даних',
    form_reason: 'Причина звернення',
    form_send: 'Вiдправити',
    reception: 'Приймальна',
    sales_department: 'Відділ продажу',
    floor_without_layouts: 'На цьому поверсі немає позначених планувань квартир',
    floor_without_plan: "До цього поверху не прив'язане планування",
    floor: 'Поверх',
    entrance: "Під'їзд",
    flat_number: 'Номер квартири',
    price_m2: 'Ціна м2',
    price_for_meter: 'Ціна за метр',
    price_unavailable: 'Ціна недоступна',
    full_price: 'Повна ціна',
    price: 'Ціна',
    levels_amount: 'Кількість рівнів',
    choose_flats: 'Виберіть квартиру',
    rooms_amount: 'Кількість кімнат',
    one_room: 'Однокімнатні',
    two_rooms: 'Двокімнатні',
    three_rooms: 'Трикімнатні',
    four_rooms: 'Від чотирьох кімнат',
    rooms: 'Кімнат',
    area: 'Площа',
    status: 'Статус',
    chess_view: 'Відображення',
    list: 'Список',
    tile: 'Плитка',
    tile_plus: 'Плитка+',
    floor_plan: 'План поверху',
    other_premises: 'Iнші приміщення',
    view_mode: 'Режим перегляду',
    object: "Об'єкт",
    object_type: "Тип об'екту",
    type: "Тип об'екту",
    object_name: "Назва об'єкту",
    city: 'Місто',
    undergroundStation: 'Метро',
    district: 'Район',
    address: 'Будівельнa адресa',
    class: 'Клас',
    levels: 'Поверховість',
    sections: 'Кількість секцій',
    section: 'Секція',
    start_of_construction: 'Початок будівництва',
    end_of_construction: 'Кінець будівництва',
    cancel: 'Cкасування',
    info: 'Iнформація',
    print_info: 'Роздрукувати інофрмацію',
    download_pdf: 'Завантажити PDF',
    continue: 'Далі',
    no_vr_photo: 'Немає VR фото',
    no_photo: 'Немає фото',
    found: 'Знайдено',
    from_them_free: 'приміщень з них вільно',
    send_order: 'Лишити заявку',
    enter_data_and_send_order: 'введіть дані і залиште заявку',
    error_text: 'Телефон технічної підтримки: +380956979583',
    SOLD_OUT: 'Продано',
    FREE: 'Вільно',
    RESERVED: 'Резерв',
    BOOKED: 'Заброньовано',
    UNAVAILABLE: 'Недоступно',
    DOCUMENTS_IN_PROGRESS: 'Оформлення документів',
    houser_remake: {
        photo_is_not_exist: 'Фото поки відсутні.',
        check_project: 'А поки ми готуємо для Вас фото, подивіться інші дизайн-проекти на сайті партнерів:'
    }
};
